@import './variables.scss';
@import './mixins.scss';

.Notification-Component {
    position: relative;
    z-index: 9999;

    .Notifications-List-Container {
        position: absolute;
        right: 0;
        height: 500px;
        width: 350px;
        margin-top: 10px;
        background: #fff;
        -webkit-box-shadow: 0px 4px 5px 0px rgba(55,101,246,0.2);
        -moz-box-shadow: 0px 4px 5px 0px rgba(55,101,246,0.2);
        box-shadow: 0px 4px 5px 0px rgba(55,101,246,0.2);
        border-radius: 10px;
        padding: 20px 15px;
        cursor: auto;

        @include xs {
            width: 100vw;
            right: -20px;
        }

        .Notification-Title {
            color: $primaryColor;
            font-weight: 700;
        }

        .Notification-List {
            min-height: 400px;
            max-height: 400px;
            overflow-y: hidden;
            overflow-x: hidden;
            border-top: 1px solid #ECECEC;
            border-bottom: 1px solid #ECECEC;
            margin-left: -15px;
            margin-right: -15px;

            &.Flex-Display {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            @include xs {
                overflow-y: auto;
            }
            
            @include sm {
                overflow-y: auto;
            }

            @include md {
                overflow-y: auto;
            }

            @include lg {
                overflow-y: hidden;
            }

            &::-webkit-scrollbar {
                position: absolute;
                width: 5px;
            }
            
            &::-webkit-scrollbar-track {
                background: #ECECEC;
            }
            
            &::-webkit-scrollbar-thumb {
                background-color: $lightBlueColor;
            }

            &:hover {
                overflow-y: auto;
            }
        }

        .Notification-Item {
            display: flex;
            min-height: 90px;
            border-bottom: 1px solid #f5f5f5;

            &.Unread-Notification {
                background-color: #E6F7FF;

                .Message-Title {
                    font-weight: 700;
                }
            }

            .container-fluid {
                padding-left: 0;
                padding-right: 0;
            }

            .row {
                width: 100%;
            }

            .Message-Text {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow-y: hidden;
                // width: 250px;
                // white-space: nowrap;
                // overflow: hidden;
                // text-overflow: ellipsis;
                color: #a1a1a1
            }

            &:hover {
                cursor: pointer;
                background: #74BBF8;

                .Message-Title,
                h5 {
                    color: #fff
                }
            }
        }
    }
}