// .Row-Unhighlight {
//   background-color: #fafafa !important;
// }

.Row-Highlight {
  background-color: white;
  font-weight: 800;
  border: 3px;

  * {
    font-weight: 800 !important;
  }
}

.Row-Highlight:hover {
  background-color: #fdfdfd !important;

  td {
    background-color: #fdfdfd !important;
  }
}