@import "variables";
@import "mixins";

.logo {
  width: 25vh;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
}

.Logo-Label {
  color: #133880;

  &.Signup-Text {
    font-weight: 800!important;
  }
}

.Signup-Container {
  padding-top: 5vh!important;

  @include lg {
    width: 70rem;
    // height: fit-content;
    // margin: auto;
    // top: 0;
    // bottom: 0;
    // left: 0;
    // right: 0;
    // position: absolute;
    background: #fff;
    padding: 0 3rem 3rem 3rem;
    //box-shadow: #a1a1a1 2px 0px 5px 1px;
  }

  .Content-Footer {
    margin: 2rem 0 !important;
    .Sub-Footer {
      text-align: center;
      margin: 3rem 0;
    }
  }
}

.DatePicker-Input {
  input[value=""]{
    color:#bfbfbf;
  }

  div {
    color: rgba(0, 0, 0, 0.85);
    text-overflow: ellipsis;
    font-size: 2rem !important;
    font-family: inherit;
  }
}