.signup-step-enter {
  position: absolute;
  opacity: 0;
}

.signup-step-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.signup-step-exit {
  position: absolute;
  opacity: .5;
}

.signup-step-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}