.Code-Key-Container {
  .col-2 {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .Code-Key {
    font-size: 45px;
    padding: 0px 3px;
    text-align: center;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom-width: 2px;
  }

  .Code-Key:focus {
    outline: none !important;
    box-shadow: none;
    -webkit-box-shadow: none;
  }
}