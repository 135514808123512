@import './../../styles/mixins.scss';
@import './../../styles/variables.scss';

body{
  background-color: $bodyBackgroundColor !important;
}

.Landing-Container {
  text-align: center;
  position: absolute;
  height: fit-content;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  .Main-Header {
    .Logo {
      height: auto;
      width: 20rem;
    }
  }

  .Content {
    margin-top: 3rem;

    @include lg {
      padding: 4rem;
      margin-top: 0;
    }

    .Content-Header {
      h2 {
        margin: 0 !important;
      }

      margin-bottom: 2rem;
    }

    .Main-Content {
      .row {
        margin-bottom: 1.5rem;
      }
    }
  }
}



