.Form-Builder-Topbar {
    position: fixed;
    top: 0;
    background: #fff;
    width: 100%;
    margin-left: -30px;
    height: 45px;
    -webkit-box-shadow: 0px 4px 5px 0px rgba(55,101,246,0.2);
    -moz-box-shadow: 0px 4px 5px 0px rgba(55,101,246,0.2);
    box-shadow: 0px 4px 5px 0px rgba(55,101,246,0.2);
    z-index: 999;

    .Form-Builder-Top-Title {
        text-transform: uppercase;
        color: #0170FE;
        font-weight: 700;
        margin-top: 8px;

        &.Title-Icon {
            &:hover {
                cursor: pointer;
            }
        }

        &.Main {
            margin-top: 10px;
            font-size: 2rem;
        }
    }

    .Toolbar-Options {
        padding-top: 10px;
        margin-left: 5px;
        font-size: 35px;
        color: #0170FE;

        &:hover {
            color: #0658c4;
            cursor: pointer;
        }
    }
}

.Form-Builder-Settings {
    position: fixed;
    top: 70px;
    right: 10px;
    background-color: #fff;
    width: 300px;
    //min-height: 50%;
    border-top: 5px solid #0170FE;
    padding: 0 10px;
    z-index: 999;
    -webkit-box-shadow: 0px 4px 5px 0px rgba(55,101,246,0.2);
    -moz-box-shadow: 0px 4px 5px 0px rgba(55,101,246,0.2);
    box-shadow: 0px 4px 5px 0px rgba(36, 38, 44, 0.2);
}

.Form-Builder-Toolbox {
    position: fixed;
    top: 70px;
    left: 10px;
    min-height: 60%;
    max-width: 250px;
    background: #fff;
    padding: 20px 10px;
    z-index: 999;
    border-top: 5px solid #0170FE;
    -webkit-box-shadow: 0px 4px 5px 0px rgba(55,101,246,0.2);
    -moz-box-shadow: 0px 4px 5px 0px rgba(55,101,246,0.2);
    box-shadow: 0px 4px 5px 0px rgba(55,101,246,0.2);

    .container-fluid {
        padding-left: 0!important;
        padding-right: 0!important;
    }

    .Toolbar-Icon {
        font-size: 1.3em;
        margin-right: 20px;
    }

    .Toolbar-Item {
        border: 1px solid #ddd!important;
        border-radius: 7px!important;
        // color: #fff!important;  
        font-weight: 700;
        height: 50px;

        &:hover {
            -webkit-box-shadow: 0px 10px 10px 0px rgba(64, 169, 255, 0.2);
            -moz-box-shadow: 0px 10px 10px 0px rgba(64, 169, 255, 0.2);
            box-shadow: 0px 10px 10px 0px rgba(64, 169, 255, 0.2);
            transform: scale(1.05);
            transition: .2s;
            cursor: grab!important;
        }
    }
}

.Form-Builder-Label {
    font-size: 1.4rem;
}

.Root-Element {
    height: 100vh;
    background: #fff;
}

.chrome-picker  {
    position: absolute;
    top: 60px;
    right: 20px;
    z-index: 999;
}

.Btn-Delete-Component {
    margin-top: 10px;
}

.With-Mark {
    border: 1px dashed #ddd;

    &:active,
    &:focus,
    &:hover {
        cursor: move;
        border-color: #0170FE;
    }
}

.Sub-Label {
    font-size: 12px!important;
    margin-top: 0;
    margin-bottom: 0;
}

.Form-Builder-Checkbox {
    margin-top: 5px!important;
    margin-bottom: 5px!important;
}

.validation-message {
    transition: .2s;
    font-size: 14px;
    font-weight: 700;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.Form-Submission-Body {
    padding: 15px 0 15px 0!important;
}