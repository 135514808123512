@import './mixins.scss';

.Onboarding-Steps {
    margin-bottom: 20px !important;

    .ant-steps-item-title {
        font-size: 1.8rem !important;
    }

    .ant-steps-item-active .ant-steps-item-title {
        font-weight: 700 !important;
        color: #0170FE !important;
    }

    @include xs {
        display: none !important;
    }
}

.Onboarding-Dialog {

    .MuiTypography-h6 {
        font-size: 3rem !important;
        font-weight: 700 !important;

        @include xs {
            text-align: center;
        }

        @include md {
            text-align: center;
        }
    }

    .MuiDialog-paper {
        min-height: 50vh!important;
        padding-bottom: 20px;
    }

    .Vector-Art-Image {
        width: 80%;
        margin: 0 auto;

        @include md {
            width: 70%;
        }

        @include lg {
            width: 60%;
        }
    }

    .ant-steps-item-process .ant-steps-item-icon {
        background: #0170FE!important;

        .ant-steps-icon {
            top: -2px !important;
        }
    }
}