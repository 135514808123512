@import './_mixins.scss';

.ant-list {
    &.Appointments-List {
        background-color: transparent !important;
        box-shadow: none !important;
        padding: 0!important;
    }
}

@media (max-width: 1330px) and (min-width: 1200px) {
    .Appointments-List>.ant-spin-nested-loading>.ant-spin-container>.ant-row > div {
        width: 50% !important;
        max-width: 50% !important;
    }
  }

.Appointment-Info-Container {
    padding: 0 !important;
}

.Appointment-Card {
    border-radius: 15px!important;
    margin-bottom: 20px!important;
    
    .ant-card-body {
        position: relative;
        padding-left: 0;
        padding-right: 0;
        background-color: #fff;
        border-radius: 20px;

        @include xs {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    .ant-card-actions {
        li {
            color: #2A9CFF !important;
            span > div > a > span {
                font-size: 13px;
                font-weight: bold;
            }

            & span a:not(.ant-btn),
            .ant-card-actions > li > span > .anticon {
                color: #2A9CFF;
                width: auto;
            }
        }   
    }

    ul.ant-card-actions {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }
}

.Double-Right-Icon {
    svg {
        height: 1rem;
    }
}

.Appointment-Booking-Title {
    color: $primaryColor !important;
    font-weight: 700!important;
    font-size: 2.2rem;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
}

.Attachment-Card-Title {
    color: $primaryColor !important;
    font-weight: 800!important;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Appointment-Detail-Title {
    color: $primaryColor !important;
    font-weight: 400;
}

.Appointment-Detail-Text {
    font-weight: 700;
    font-size: 1.8rem;
    word-break: break-word;
    margin: 0;
}

.View-Details-Qr {
    max-width: 245px;
}

.View-Details-Ticket {
    position: absolute;
    left: 0;
    bottom: 0;
}

.Appointment-Booking-Details {
    color: #193B68!important;
    font-size: 2.5rem;

    &.Location {
        text-overflow: ellipsis;
    }

    svg {
        margin-top: 6px;
        margin-right: 5px;
    }
}

.Appointment-Booking-Buttons {
    @include md {
        display: flex;
    }
}

.Qr-Code {
    margin-top: 8px;
    width: 160px;
    margin-right: '-5%';
}

.Appointment-Booking-Parent-Location {
    color: #4c4b4bd9;
    font-size: 12px;
    margin-left: 27px;
}

.Appointment-Booking-Status {
    font-size: 1.2rem;
}
.ant-badge-not-a-wrapper:not(.ant-badge-status) {
    vertical-align: unset!important;
    margin-bottom: 15px;
}

.ant-badge-count-sm {
    height: 22px!important;
    font-size: 17px!important;
    line-height: 23px!important;
    border-radius: 21px!important;
}

.Card-Gradient-Primary {
    background: #fff !important;
    box-shadow: 0px 10px 10px 0px rgba(1, 111, 254, 0.2);
    border-radius: 20px!important;
    min-height: 170px;
    display: flex;
    align-items: flex-start;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    
    @include xs {
        margin-bottom: 10px!important;
    }

    @include sm {
        margin-bottom: 10px!important;
    }

    .ant-card-body {
        width: 100%;
        padding: 32px 24px;
    }
}

.Contact-Cancellation {
    margin-bottom: 15px;

    @include xs {
        text-align: left;
    }

    @include md {
        text-align: right;
    }

    h3 {
        @include xs {
            font-size: 1.7rem;
        }

        @include md {
            font-size: 1.4rem;
        }
    }
}

.Floating-Call-Button {
    border-radius: 50%;
    background: #35C235!important;
    border: none!important;
    box-shadow: 0 0 0 0 rgba(53, 194, 53, 0.7);
    -webkit-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1)!important;
    -moz-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1)!important;
    -ms-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1)!important;  
    animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1)!important;
    transition: all 300ms ease-in-out;

    &:hover {
        -webkit-animation: none!important;
    }
}

.Modal-Message-Details {
    .ant-modal-body {
        padding: 0!important;
    }
}

.Details-Forms-Table {
    .ant-table {
        border-radius: 10px !important;
    }
}

.Card-Content-Top-Margin {
    margin-top: 2rem;
}
/* Animation */

@-webkit-keyframes pulsing {
  to {
    
    box-shadow: 0 0 0 20px rgba(81, 255, 0, 0.2);
  }
}

@-moz-keyframes pulsing {
  to {
    box-shadow: 0 0 0 20px rgba(81, 255, 0, 0.2);
  }
}

@-ms-keyframes pulsing {
  to {
    box-shadow: 0 0 0 20px rgba(81, 255, 0, 0.2);
  }
}

@keyframes pulsing {
  to {
    box-shadow: 0 0 0 20px rgba(81, 255, 0, 0.1);
  }
}

.ant-btn[disabled] {
    opacity: 0.6 !important;
    color: white !important;
    background-color: gray !important;
}

.ant-btn[disabled]:hover {
    color: white !important;
    background-color: gray !important;
}

.ant-btn.Check-In-Button[disabled] {
    border: 1px solid #f5f5f5 !important;
}

.img-responsive {
    max-width: 180px;
}

.Appointment-Tile-Buttons-Wrap {
    @include md {
        text-align: right;
    }
}
